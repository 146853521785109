import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BMCalculator from '../views/BMCalculator.vue'
import PageNotFound from "@/components/PageNotFound.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/inf',
    name: 'Home',
    component: Home
  },
  {
    path: '/bm',
    name: 'BM',
    component: BMCalculator
  },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
