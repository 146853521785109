<template>
  <div class="container mt-5" :id="{ 'bg-transparent': isLoading }">
    <div
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
      v-if="wrongFormat"
    >
      Das hochgeladene PDF ist im <strong>falschen Format</strong>. Vielleicht
      bist du beim falschen Rechner. In der Navigationsleiste sind alle Rechner
      aufgelistet
    </div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Berufsmatura</li>
      </ol>
    </nav>
    <div v-if="!reportLoaded">
      <empty-state @upload="previewFile" />
    </div>
    <div class="row" v-if="grades.length > 0">
      <div class="col-12">
        <h1 class="text-center">
          Gesamtdurchschnitt
          <b :class="{ 'text-success': success, 'text-danger': !success }">{{
            Math.round(avg * 10) / 10
          }}</b>
        </h1>
      </div>
      <div class="col-12 col-lg-6">
        <div v-if="!success" class="row">
          <p class="col-12">
            <strong>
              {{
                minusScore > 2
                  ? `Zu viele Notenpunkte im Minus (${minusScore})`
                  : ""
              }}
            </strong>
          </p>
          <br />
          <p class="col-12">
            <strong>
              {{ insufficientGrades >= 2 ? `Zu viele ungenügende Noten (${insufficientGrades})` : "" }}
            </strong>
          </p>
          <br />
          <p class="col-12">
            <strong>
              {{ 4 > avg ? `Ungenügender Schnitt` : "" }}
            </strong>
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-if="isLoading">
      <div
        class="spinner-grow text-primary"
        role="status"
        style="width: 7rem; height: 7rem"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="accordion" v-if="grades.length > 0" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Abschlussnotenrechner
            </button>
          </h2>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="card-body bm-calculator-card">
            <div class="row m-2 pt-3" style="color: white">
              <div
                v-for="(el, i) in activeFinalGrades"
                :key="i"
                class="col-12 col-lg-4"
              >
                <div
                  class="card mb-3"
                  :class="getBoarderColor(el)"
                  style="max-width: 18rem"
                  v-if="el.active"
                >
                  <div
                    class="card-header bg-transparent text-dark"
                    :class="getBoarderColor(el)"
                  >
                    {{ el.avg }}
                  </div>
                  <div class="card-body text-dark">
                    <h5 class="card-title">{{ el.title }}</h5>
                    <div class="card-text">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"
                            >Erfahrungsnote</span
                          >
                        </div>
                        <input
                          type="number"
                          min="0"
                          max="6"
                          step="0.5"
                          disabled
                          class="form-control"
                          v-model="el.roundedGrade"
                          @input="calculateAvg"
                          placeholder="Erfahrungsnote"
                          aria-label="Erfahrungsnote"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"
                            >Prüfungsnote</span
                          >
                        </div>
                        <input
                          type="number"
                          min="0"
                          @input="calculateAvg"
                          max="6"
                          step="0.5"
                          v-model="el.finalExamGrade"
                          class="form-control"
                          placeholder="Prüfungsnote"
                          aria-label="Prüfungsnote"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-footer bg-transparent text-dark"
                    :class="getBoarderColor(el)"
                  >
                    <button type="button" class="btn btn-outline-danger" @click="removeGrade(el)">
                      Entfernen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button
              class="btn btn-link collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Semesternoten
            </button>
          </h2>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            <div class="row" v-if="grades.length > 0">
              <div
                v-for="(el, i) in grades"
                :key="i"
                class="col-12 col-lg-6 margin-top-2"
              >
                <BmGradeContainer
                  :subject="el"
                  :id="i"
                  @update="updateFinalGrades"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import BmGradeContainer from "@/components/BmGradeContainer.vue";
import EmptyState from "@/components/EmptyState";

export default {
  name: "BMCalculator",
  components: {
    BmGradeContainer,
    EmptyState,
  },
  computed: {
    activeFinalGrades() {
      return this.finalGrades.filter((grade) => grade.active)
    }
  },
  data() {
    return {
      grades: [],
      avg: 0,
      isLoading: false,
      finalGrades: [],
      success: true,
      minusScore: 0,
      reportLoaded: false,
      wrongFormat: false,
      insufficientGrades: 0,
    };
  },
  methods: {
    removeGrade(el) {
      const i = this.finalGrades.indexOf(el);
      this.finalGrades[i].active = false;
      this.calculateAvg();
    },
    updateFinalGrades(e) {
      let index = this.finalGrades.findIndex((g) => g.title === e.title);
      if (index === -1) {
        e.roundedGrade = Math.round(e.grade * 2) / 2;
        e.finalExamGrade = Math.round(e.grade * 2) / 2;
        e.active = true;
        this.finalGrades.push(e);
        this.calculateAvg();
      } 
      else {
        const el = this.finalGrades[index];
        if (e.grade !== el.grade) {
          el.roundedGrade = Math.round(e.grade * 2) / 2;
          // el.finalExamGrade = Math.round(e.grade * 2) / 2;
          this.calculateAvg();
        }
      }
    },
    calculateAvg() {
      this.success = true;
      this.minusScore = 0;
      const total = this.finalGrades.reduce((prev, subject) => {
        if (!subject.active) {
          return prev;
        }
        subject.avg =
          (parseFloat(subject.finalExamGrade) +
            parseFloat(subject.roundedGrade)) /
          2;
        subject.avg = Math.round(subject.avg * 2) / 2;
        return prev + subject.avg;
      }, 0);
      const totalActiveGrades = this.finalGrades.reduce((prev, subject) => {
        if (!subject.active) {
          return prev;
        }
        return prev + 1;
      }, 0)
      this.avg = total / totalActiveGrades;
      this.setMinusScore();
      if (this.minusScore > 2 || this.avg < 4 || this.insufficientGrades > 2) {
        this.success = false;
      }
    },
    setMinusScore() {
      this.insufficientGrades = 0;
      this.minusScore = this.finalGrades.reduce((prev, curr) => {
        if (curr.avg < 4 && curr.active) {
          this.insufficientGrades++;
          return prev + 4 - curr.avg;
        }
        return prev;
      }, 0);
    },
    getBoarderColor(el) {
      if (el.avg >= 4.5) {
        return "border-success";
      }
      if (el.avg < 4) {
        return "border-danger";
      }
      return "border-warning";
    },
    previewFile(file) {
      const formData = new FormData();
      formData.append("files", file);
      this.isLoading = true;
      this.reportLoaded = true;
      const url = `https://pdf-backend.scapp.swisscom.com/bm`;
      // const url = `http://localhost:3000/bm`;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.grades = res.data.filter((d) => d.length > 1);
          this.grades.pop();
          const finalGrades = [];
          for (const grade of this.grades) {
            const el = {};
            el.title = grade[0];
            el.grades = [];
            grade.splice(0, 1);
            for (const g of grade) {
              el.grades.push(g);
            }
            finalGrades.push(el);
          }
          this.wrongFormat = finalGrades.length === 0;
          this.grades = finalGrades;
          this.reportLoaded = !this.wrongFormat;
          this.isLoading = false;
        })
        .catch(() => (this.reportLoaded = false));
    },
  },
};
</script>
<style scoped>
#bg-transparent {
  opacity: 0.1;
}

#bm-calculator-card {
  background-color: red;
}
</style>