<template>
  <div>
    <div class="container mt-5">
      <div v-if="michiHs" class="alert alert-success" role="alert">
        <a href="#" class="alert-link">hs michi</a>
      </div>
      <div
        ref="testAlert"
        class="alert alert-warning alert-dismissible fade show"
        role="alert"
      >
        <strong>In Entwicklung!</strong>
      </div>
      <div class="jumbotron">
        <h1>Abschlussrechner</h1>
        <div class="lead row justify-content-center">
          <div class="col-lg-4 col-12">
            <router-link to="/bm" class="stretched-link">Berufsmatura</router-link>
          </div>
          <div class="col-lg-4 col-12">
            <router-link to="/inf" class="stretched-link"
              >Informatik</router-link
            >
          </div>
        </div>
        <hr class="my-4" />
        <p>by Maximilian Scheuber</p>
        <!-- <a
        <p class="text-monospace">by Maximilian Scheuber</p>
        <a
          class="btn btn-primary btn-lg"
          href="#"
          @click="showAlert"
          role="button"
          >Learn more</a
        > -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
  data() {
    return {
      michiHs: false,
    };
  },
  methods: {
    showAlert() {
      if (this.michiHs) {
        return;
      }
      this.michiHs = true;
      setTimeout(() => (this.michiHs = false), 2000);
    },
  },
};
</script>