<template>
  <div @dragover.prevent @drop.prevent="addFile">
      <div class="col-12">
        <label for="file-upload" class="custom-file-upload text-center mx-auto" style="margin-bottom: 0;">
            <i class="fa-solid fa-upload p-3 fa-5x"></i>
            <p class="font-weight-bold h4">Zeugnis hier hochladen</p>
        </label>
      </div>
      <input ref="input" id="file-upload" accept="application/pdf" @change="addFile" class="row" type="file"/>
  </div>
</template>

<script>

export default {
  name: 'EmptyState',
  data() {
    return {
      selectedFile: null,
      fileSize: 0.0,
    }
  },
  methods: {
    async addFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if(!files) return;
      for (const el of Array.from(files)) {
        if (el.name.endsWith(".pdf")) {
          this.fileSize += this.calculateFileSize(el.size);
          this.selectedFile = el;
        }
      }
      this.$refs.input.value = "";
      this.$emit("upload", this.selectedFile)
    },
    calculateFileSize(bytes) {
      return parseFloat((bytes / (1024*1024)).toString());
    },
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
  min-height: 5vw;
  height: auto;
  border: 5px inset #007bff;
}

.files {
  border-radius: 5px;
}

.file-names {
  transition: 0.4s;
  color: black;
}

.folder-upload {
  cursor: pointer;
  color: black;
}

.folder-upload:hover {
  cursor: pointer;
  color: rgb(0, 17, 85);
}
.file-names:hover {
  color: red;
  cursor: pointer;
}
</style>
