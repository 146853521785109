<template>
  <div v-if="subject">
    <div class="bg-dark" style="color: white">
      <h3>{{ subject.title }} {{ average }}</h3>
      <h3>(Anzahl: {{ subject.grades.length }})</h3>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="input-group mb-3">
          <input type="number" min="1" max="6" step="0.5" class="form-control" placeholder="Note" v-model="grade"
            aria-label="Note" aria-describedby="basic-addon1" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <button
          type="button"
          @click="addGrade"
          :disabled="grade === 0 || grade.length === 0"
          class="btn btn-outline-dark btn-block"
        >
          +
        </button>
      </div>
    </div>
    <div class="list-group" id="grade-list">
      <p v-for="(grade, index) in finalGrades" :key="index" @click="removeGrade(index)"
        class="list-group-item list-group-item-action" v-bind:class="{ active: active === index }">
        {{ grade }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "BMGradeContainer",
  props: ["subject", "id"],
  computed: {
    average() {
      const total = this.subject.grades.reduce((acc, grade) => {
        return parseFloat(acc) + parseFloat(grade);
      });
      let grade = total / this.subject.grades.length;
      grade = Math.round(grade * 100) / 100;
      const i = this.id || this.subject.grades.length;
      const title = this.subject.title;
      this.$emit("update", { grade, i, title });
      return grade;
    },
  },
  data() {
    return {
      avg: 0,
      active: false,
      weighting: 100,
      grade: 5,
      finalGrades: [],
    };
  },
  mounted() {
    this.finalGrades = this.subject.grades;
  },
  methods: {
    removeGrade(index) {
      this.finalGrades.splice(index, 1)
      this.calculateAvg();
    },
    addGrade() {
      this.finalGrades.push(this.grade.toString());
      this.grade = 5;
      this.calculateAvg();
    },
    calculateAvg() {
      let total = 0;
      for (const grade of this.finalGrades) {
        total += grade;
      }
      this.avg = total / this.finalGrades.length;
    },
  },
};
</script>
<style scoped>
#grade-list {
  height: 25vw;
  overflow: scroll;
}
</style>