<template>
  <div class="container mt-5" :id="{ 'bg-transparent': isLoading }">
    <div
        class="alert alert-warning alert-dismissible fade show"
        role="alert"
        v-if="wrongFormat"
    >
      Das hochgeladene PDF ist im <strong>falschen Format</strong>. Vielleicht bist du beim falschen Rechner. In der Navigationsleiste sind alle Rechner aufgelistet
    </div>
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Informatik
          </li>
        </ol>
      </nav>
    </div>
    <div v-if="!reportLoaded">
      <empty-state @upload="previewFile" />
    </div>
    <div class="row" v-if="grades.length > 0">
      <h1>
        Gesamtdurchschnitt <b>{{ Math.round(avg * 10) / 10 }}</b>
      </h1>
    </div>
    <div class="d-flex justify-content-center" v-if="isLoading">
      <div class="spinner-grow text-primary" role="status" style="width: 7rem; height: 7rem;">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      v-if="grades.length > 0"
      class="row m-2 pt-3 bg-dark white"
      style="color: white"
    >
      <div v-for="(el, i) in averages" :key="i" class="col-12 col-lg-6">
        <div class="row">
          <div class="input-group mb-3 col-12 col-lg-2">
            <h4>{{ Math.round(el.grade * 2) / 2 }}</h4>
          </div>
          <div class="input-group mb-3 col-12 col-lg-5">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Gewichtung</span>
            </div>
            <input
              type="number"
              min="0"
              max="300"
              step="5"
              btn-outline-secondary
              v-model="el.weight"
              @change="calculateAvg"
              class="form-control"
              aria-label="Gewichtung"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="grades.length > 0">
      <div
        v-for="(el, i) in grades"
        :key="i"
        class="col-12 col-lg-6 margin-top-2"
      >
        <grade-container @update="updateGrades" :grades="el" :i="i" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import GradeContainer from "@/components/GradeContainer.vue";
import EmptyState from "@/components/EmptyState";
export default {
  name: "Home",
  components: {
    GradeContainer,
    EmptyState,
  },
  data() {
    return {
      grades: [],
      averages: [],
      avg: 0,
      isLoading: false,
      reportLoaded: false,
      wrongFormat: false,
    };
  },
  methods: {
    updateGrades(e) {
      this.averages[e.i] = e;
      this.averages[e.i].weight = e.i === 0 ? 80 : 20;
      this.calculateAvg();
    },
    calculateAvg() {
      let total = 0;
      let sum = 0;
      for (let i = 0; i < this.averages.length; i++) {
        const g = Math.round(this.averages[i].grade * 2) / 2;
        sum += g * (this.averages[i].weight / 100);
        total += this.averages[i].weight / 100;
      }
      this.avg = sum / total;
    },
    previewFile(file) {
      var formData = new FormData();
      formData.append("files", file);
      this.isLoading = true;
      this.reportLoaded = true;
      const url = `https://pdf-backend.scapp.swisscom.com/`;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data[0].length === 0 || res.data[1].length === 0) {
            this.wrongFormat = true;
            this.reportLoaded = false;
          } else {
            this.wrongFormat = false;
            this.grades = res.data;
          }
          this.isLoading = false;
        }).catch(() => this.reportLoaded = false);
    },
  },
};
</script>
<style scoped>
#bg-transparent {
  opacity: 0.1;
}
</style>