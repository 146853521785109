<template>
  <div>
    <div class="bg-dark" style="color: white">
      <h1>{{ i === 0 ? "BS" : "ÜK" }} {{ Math.round(average * 10) / 10 }}</h1>
      <h3>(Anzahl: {{ grades.length }})</h3>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Title"
            v-model="grade.title"
            aria-label="sdf"
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="input-group mb-3">
          <input
            type="number"
            min="1"
            max="6"
            step="0.5"
            class="form-control"
            placeholder="Note"
            v-model="grade.grade"
            aria-label="Note"
            aria-describedby="basic-addon1"
          />
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <button
          type="button"
          @click="addGrade"
          :disabled="grade.grade === 0 || grade.grade.length === 0"
          class="btn btn-outline-dark btn-block"
        >
          +
        </button>
      </div>
    </div>
    <div class="list-group" id="grade-list">
      <p
        v-for="(grade, index) in finalGrades"
        :key="index"
        @click="finalGrades.splice(index, 1)"
        class="list-group-item list-group-item-action"
        v-bind:class="{ active: active === index }"
      >
        {{ grade[0] }}: {{ grade[1] }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "GradeContainer",
  props: ["grades", "i"],
  computed: {
    average() {
      let total = 0;
      for (const grade of this.finalGrades) {
        total += parseFloat(grade[1]);
      }
      const grade = total / this.finalGrades.length;
      const i = this.i;
      const abs = (grade * this.weighting) / 100;
      const sum = this.weighting / 100;
      this.$emit("update", { grade, i, abs, sum });
      return grade;
    },
  },
  data() {
    return {
      avg: 0,
      active: false,
      weighting: 100,
      grade: {
        title: "Test",
        grade: 5,
      },
      finalGrades: [],
    };
  },
  mounted() {
    this.finalGrades = this.grades;
  },
  methods: {
    addGrade() {
      const _grade = [this.grade.title, this.grade.grade];
      this.finalGrades = [_grade].concat(this.finalGrades);
      this.grade = {
        title: "",
        grade: 0,
      };
      this.calculateAvg();
      this.changeWeight();
    },
    changeWeight() {
      let total = 0;
      for (const grade of this.finalGrades) {
        total += parseFloat(grade[1]);
      }
      const grade = total / this.finalGrades.length;
      const i = this.i;
      const abs = (grade * this.weighting) / 100;
      const sum = this.weighting / 100;
      this.$emit("update", { grade, i, abs, sum });
    },
    calculateAvg() {
      let total = 0;
      for (const grade of this.finalGrades) {
        total += grade[1];
      }
      this.avg = total / this.finalGrades.length;
    },
  },
};
</script>
<style scoped>
#grade-list {
  height: 25vw;
  overflow: scroll;
}
</style>